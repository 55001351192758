<template>
  <div>
    <nav
      class="flex mb-6 px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-100"
      aria-label="Breadcrumb"
    >
      <ol
        class="inline-flex items-center justify-center space-x-1 md:space-x-3 m-0"
      >
        <li class="inline-flex items-center justify-center">
          <a
            href="/"
            class="inline-flex items-center justify-center text-sm font-medium text-gray-900"
          >
            <div class="flex justify-center">
              <div>
                <svg
                  class="w-4 h-4 mr-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"
                  />
                </svg>
              </div>

              <div>Inicio</div>
            </div>
          </a>
        </li>
        <li class="flex items-center justify-center">
          <div>
            <svg
              class="w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </div>
        </li>
        <li class="flex items-center justify-center">
          <a
            href="/cart"
            class="inline-flex items-center justify-center text-sm font-medium text-gray-900"
          >
            Carrito
          </a>
        </li>
        <li class="flex items-center justify-center">
          <div>
            <svg
              class="w-3 h-3 text-gray-400 mx-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          </div>
        </li>
        <li class="inline-flex items-center justify-center">
          <a
            href="#"
            class="inline-flex items-center justify-center text-sm text-gray-900 font-bold"
          >
            Checkout
          </a>
        </li>
      </ol>
    </nav>

    <div id="wait-modal" class="w-screen h-full" v-if="showTerms">
      <div
        class="flex flex-col w-full h-full items-center justify-center overflow-x-scroll"
      >
        <div
          class="w-1/2 h-1/2 overflow-x-scroll border border-gray-900 bg-white"
        >
          <h2 class="bg-gray-900 text-white text-white font-bold px-4 py-2">
            Terminos y condiciones
          </h2>
          <div class="p-6">
            <h2 class="text-xl font-semibold mb-3">1. Generalidades</h2>
            <p class="mb-4">
              Estos términos y condiciones se aplican a todos los envíos y
              entregas realizados por [Nombre de tu E-commerce]. Al realizar un
              pedido, el cliente acepta estos términos.
            </p>

            <h2 class="text-xl font-semibold mb-3">
              2. Procesamiento de Pedidos
            </h2>
            <p class="mb-4">
              Los pedidos se procesarán dentro de un plazo de [x] días hábiles
              después de recibir la confirmación del pago. Los días hábiles
              excluyen sábados, domingos y festivos.
            </p>

            <h2 class="text-xl font-semibold mb-3">3. Métodos de Envío</h2>
            <p class="mb-4">
              Ofrecemos diversas opciones de envío, incluyendo pero no
              limitándose a envío estándar, express y envío con seguimiento. Los
              costos y tiempos de entrega varían según el método elegido y se
              detallarán al momento de la compra.
            </p>

            <h2 class="text-xl font-semibold mb-3">4. Tiempos de Entrega</h2>
            <p class="mb-4">
              Los tiempos de entrega estimados son solo eso, estimaciones. No
              garantizamos tiempos de entrega debido a factores fuera de nuestro
              control, como retrasos por parte de la empresa de envíos o
              condiciones climáticas adversas.
            </p>

            <h2 class="text-xl font-semibold mb-3">5. Costos de Envío</h2>
            <p class="mb-4">
              Los costos de envío se calculan en función del peso, tamaño del
              paquete y destino. El costo total de envío se proporcionará al
              cliente antes de finalizar la compra.
            </p>

            <h2 class="text-xl font-semibold mb-3">
              6. Seguimiento de Pedidos
            </h2>
            <p class="mb-4">
              Cuando esté disponible, proporcionaremos un número de seguimiento
              para que el cliente pueda rastrear el estado de su pedido en
              línea.
            </p>

            <h2 class="text-xl font-semibold mb-3">
              7. Direcciones de Entrega
            </h2>
            <p class="mb-4">
              El cliente es responsable de proporcionar una dirección de entrega
              correcta y completa. No nos hacemos responsables por pedidos no
              entregados debido a direcciones incorrectas.
            </p>

            <h2 class="text-xl font-semibold mb-3">8. Entrega Fallida</h2>
            <p class="mb-4">
              En caso de una entrega fallida por ausencia del cliente o
              dirección incorrecta, el cliente será responsable por los costos
              adicionales incurridos para reenviar o devolver el pedido.
            </p>

            <h2 class="text-xl font-semibold mb-3">9. Aduanas y Aranceles</h2>
            <p class="mb-4">
              Para envíos internacionales, el cliente es responsable de todos
              los aranceles e impuestos de aduana que puedan aplicarse.
            </p>

            <h2 class="text-xl font-semibold mb-3">
              10. Política de Devoluciones
            </h2>
            <p class="mb-4">
              Las devoluciones se manejarán de acuerdo con nuestra Política de
              Devoluciones, la cual es un documento separado.
            </p>

            <h2 class="text-xl font-semibold mb-3">
              11. Modificación de Términos
            </h2>
            <p class="mb-4">
              Nos reservamos el derecho de modificar estos términos y
              condiciones sin previo aviso.
            </p>
          </div>

          <div class="flex items-center justify-center mb-6">
            <button
              class="bg-gray-300 font-bold px-4 py-2 rounded-full"
              @click="cancelTerms"
            >
              Cancelar
            </button>
            <button
              class="ml-6 bg-green-500 text-white font-bold px-4 py-2 rounded-full"
              @click="acceptTerms"
            >
              Acepto
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center">
      <div>
        <div>
          <h2 class="text-2xl text-gray-900">
            Selecciona un método de entrega
          </h2>
        </div>
        <div
          class="uk-margin uk-grid-small uk-child-width-auto uk-grid"
          v-if="storefrontStorefront.home_delivery == 1"
        >
          <label
            ><input
              class="uk-radio"
              type="radio"
              name="shipping-addresses"
              checked
              v-model="multipleShippingAddresses"
              :value="false"
            />
            Entrega en una dirección</label
          >
          <label
            ><input
              class="uk-radio"
              type="radio"
              name="shipping-addresses"
              v-model="multipleShippingAddresses"
              :value="true"
            />
            Entrega en múltiples direcciones</label
          >
        </div>
      </div>

      <div class="uk-text-center">
        <router-link
          class="block w-full py-2 px-4 uk-button-primary rounded-2xl text-white font-bold"
          to="/profile"
        >
          <div class="flex items-center justify-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                />
              </svg>
            </div>
            <div class="ml-2">Administrar Direcciones</div>
          </div>
        </router-link>
      </div>
    </div>

    <hr />

    <div class="flex flex-col">
      <div class="w-full">
        <!-- Multiple shipping addresses -->
        <div v-if="multipleShippingAddresses">
          <div
            v-for="(group, indexz) in groupedProducts"
            :key="'group-' + indexz"
            class="flex py-4 md:flex-row md:py-0 items-center justify-between overflow-hidden mb-4 bg-white rounded-xl shadow border border-gray-200"
          >
            <div @click="group.isOpen = !group.isOpen">
              <div class="relative flex justify-center items-center">
                <div>
                  <router-link
                    v-if="group.product.quotable.media.length"
                    :to="
                      '/store/' +
                      (group.product.quotable.redeemable_type ===
                      'App\\Models\\Product'
                        ? group.product.quotable.redeemable.id
                        : group.product.quotable.redeemable.product.id)
                    "
                  >
                    <div class="flex items-center bg-gray-900">
                      <div class="p-4 h-full">
                        <span class="text-xs text-white">{{ indexz + 1 }}</span>
                      </div>
                      <img
                        :src="group.product.quotable.media[0].url"
                        alt=""
                        class="w-32 h-full object-cover border-r boder-gray-200"
                      />
                    </div>
                  </router-link>
                </div>

                <div
                  class="top-0 mx-4 pr-2 font-bold flex items-center justify-center relative rounded-full w-24 h-6 bg-gray-900"
                >
                  <div>
                    <img
                      v-if="pointsIcon"
                      :src="pointsIcon"
                      alt=""
                      width="50px"
                    />
                  </div>
                  <div class="text-white text-sm">
                    {{ formatThousands(group.product.quotable.current_price) }}
                  </div>
                </div>

                <div class="flex flex-col items-center m-4">
                  <div>
                    <router-link
                      class="text-lg font-bold text-gray-700 hover:text-gray-900"
                      :to="
                        '/store/' +
                        (group.product.quotable.redeemable_type ===
                        'App\\Models\\Product'
                          ? group.product.quotable.redeemable.id
                          : group.product.quotable.redeemable.product.id)
                      "
                    >
                      {{
                        group.product.quotable.redeemable_type ===
                        "App\\Models\\Product"
                          ? group.product.quotable.display_name
                          : group.product.quotable.redeemable.product
                              .storefront_redeemables[0].display_name +
                            " - " +
                            group.product.quotable.redeemable.value
                      }}
                      ({{ group.items.length }} items)
                    </router-link>
                  </div>
                  <div class="text-xs text-center text-gray-600">
                    <strong>Agregado el: </strong> <br />{{
                      formatDatetime(group.product.created_at)
                    }}
                  </div>

                  <div
                    class="bg-green-400 font-bold text-white text-xs px-4 py-2 rounded-full"
                    v-if="
                      group.product.quotable.current_stock > group.items.length
                    "
                  >
                    (Stock:
                    {{ formatThousands(group.product.quotable.current_stock) }}
                    )
                  </div>

                  <div
                    class="bg-orange-400 font-bold text-white text-xs px-4 py-2 rounded-full"
                    v-if="
                      group.product.quotable.current_stock == group.items.length
                    "
                  >
                    (Stock:
                    {{ formatThousands(group.product.quotable.current_stock) }}
                    )
                  </div>

                  <div
                    class="bg-red-400 font-bold text-white text-xs px-4 py-2 rounded-full"
                    v-if="
                      group.product.quotable.current_stock < group.items.length
                    "
                  >
                    (Stock:
                    {{ formatThousands(group.product.quotable.current_stock) }}
                    )
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                v-for="(userQuotable, index) in group.items"
                :key="`userQuotable-${index}-${userQuotable.id}`"
                class="flex py-4 mx-4 md:flex-row md:py-0 items-center justify-between overflow-hidden mb-4"
              >
                <div class="text-center font-bold text-gray-700 pr-2">
                  <span># </span>
                  {{ index + 1 }}
                </div>

                <div class="mr-4">
                  <select
                    :key="'select-' + index + '-' + userQuotable.id"
                    class="bg-gray-200 px-4 py-2 rounded"
                    v-model="userQuotable.selectedAddressId"
                  >
                    <option value="">Selecciona una dirección</option>
                    <option
                      v-for="address in addresses"
                      :key="address.id"
                      :value="address.id"
                    >
                      {{ address.first_name }} {{ address.last_name }} | <br />
                      {{ address.address }}, Zona {{ address.zone }} | <br />
                      {{ address.city.name }}, {{ address.city.state.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Multiple shipping addresses -->

        <!-- Single shipping address -->
        <div v-else>
          <div
            v-for="(userQuotable, index) in userQuotables"
            :key="`userQuotable-${index}-${userQuotable.id}`"
            class="flex flex-col py-4 md:flex-row md:py-0 items-center justify-between overflow-hidden mb-4 bg-white rounded-xl shadow border border-gray-200"
          >
            <div class="relative flex justify-center items-center">
              <div>
                <router-link
                  v-if="userQuotable.quotable.media.length"
                  :to="
                    '/store/' +
                    (userQuotable.quotable.redeemable_type ===
                    'App\\Models\\Product'
                      ? userQuotable.quotable.redeemable.id
                      : userQuotable.quotable.redeemable.product.id)
                  "
                >
                  <div class="flex items-center bg-gray-900">
                    <div class="p-4 h-full w-12 text-center">
                      <span class="text-xs text-white font-bold">{{ index + 1 }}</span>
                    </div>
                    <img
                      :src="userQuotable.quotable.media[0].url"
                      alt=""
                      class="w-32 h-full object-cover border-r boder-gray-200"
                    />
                  </div>
                </router-link>
              </div>

              <div
                class="top-0 mx-4 pr-2 font-bold flex items-center justify-center relative rounded-full w-24 h-6 bg-gray-900"
              >
                <div>
                  <img
                    v-if="pointsIcon"
                    :src="pointsIcon"
                    alt=""
                    width="50px"
                  />
                </div>
                <div class="text-white text-sm">
                  {{ formatThousands(userQuotable.quotable.current_price) }}
                </div>
              </div>
            </div>

            <div class="flex flex-col items-center m-4">
              <div>
                <router-link
                  class="text-lg font-bold text-gray-700 hover:text-gray-900"
                  :to="
                    '/store/' +
                    (userQuotable.quotable.redeemable_type ===
                    'App\\Models\\Product'
                      ? userQuotable.quotable.redeemable.id
                      : userQuotable.quotable.redeemable.product.id)
                  "
                >
                  {{
                    userQuotable.quotable.redeemable_type ===
                    "App\\Models\\Product"
                      ? userQuotable.quotable.display_name
                      : userQuotable.quotable.redeemable.product
                          .storefront_redeemables[0].display_name +
                        " - " +
                        userQuotable.quotable.redeemable.value
                  }}
                </router-link>
              </div>
              <div class="text-xs text-center text-gray-600">
                <strong>Agregado el: </strong> <br />{{
                  formatDatetime(userQuotable.created_at)
                }}
              </div>

              <div
                class="bg-green-400 font-bold text-white text-xs px-4 py-2 rounded-full"
                v-if="
                  userQuotable.quotable.current_stock > userQuotable.quantity
                "
              >
                (Stock:
                {{ formatThousands(userQuotable.quotable.current_stock) }}
                )
              </div>

              <div
                v-if="
                  userQuotable.quotable.current_stock == userQuotable.quantity
                "
                class="bg-orange-400 text-white text-xs font-bold px-4 py-2 rounded-full"
              >
                (Stock:
                {{ formatThousands(userQuotable.quotable.current_stock) }}
                )
              </div>
              <div
                v-if="
                  userQuotable.quotable.current_stock < userQuotable.quantity
                "
                class="bg-red-500 text-white text-xs font-bold px-4 py-2 rounded-full"
              >
                (Stock:
                {{ formatThousands(userQuotable.quotable.current_stock) }}
                )
              </div>
            </div>

            <div class="text-center font-bold text-gray-700">
              <span>Cantidad:</span><br />
              {{ userQuotable.quantity }}
            </div>

            <div class="flex justify-center items-center mx-6">
              <div>
                <img v-if="pointsIcon" :src="pointsIcon" alt="" width="50px" />
              </div>
              <div>
                {{
                  formatThousands(
                    userQuotable.quantity * userQuotable.quotable.current_price
                  )
                }}
                {{ pointsName }}
              </div>
            </div>
          </div>
        </div>
        <!-- End Single shipping address -->

        <!-- Shipping addresses -->
        <div v-if="!multipleShippingAddresses">
          <h2 class="text-2xl font-bold text-gray-900 mt-8">
            Seleccione una dirección:
          </h2>
          <div
            v-for="(address, index) in addresses"
            :key="address.id"
            class="uk-width-1-1 mt-4"
          >
            <transition name="fade">

            <div
                v-if="index === 0 && !showAllAddresses"
              class="uk-card uk-card-default uk-padding-small uk-border-rounded"
            >
              <label>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold uk-text-large">
                    <input
                      class="uk-radio"
                      type="radio"
                      name="address"
                      v-model="mainAddressId"
                      :value="address.id"
                    />
                    <span class="uk-margin-small-left"
                      >{{ address.first_name }} {{ address.last_name }}</span
                    >
                  </div>

                  <div
                    v-if="address.id in shippingLabels"
                    class="uk-margin-right uk-link-reset uk-flex uk-flex-middle"
                  ></div>
                </div>
                <div>
                  <span class="uk-text-bold">Teléfono:</span>
                  {{ address.phone }}
                </div>
                <div>
                  <span class="uk-text-bold">Dirección:</span>
                  {{ address.address }}, Zona
                  {{ address.zone }}
                </div>
                <div>
                  {{ address.city.name }}, {{ address.city.state.name }},
                  {{ address.city.state.country.spanish }}
                </div>

                <div
                  class="uk-margin-top uk-text-bold"
                  v-if="
                    multipleShippingAddresses &&
                    model.addresses[address.id].selected
                  "
                >
                  <div>Distribución de items</div>

                  <div
                    v-for="(userQuotable, index) in userQuotables"
                    :key="
                      'address-' + address.id + '-userQuotableIndex-' + index
                    "
                    class="flex items-center justify-between mt-2"
                  >
                    <div>
                      <input
                        class="w-12 bg-gray-100 border border-gray-300 rounded-lg p-1 text-center"
                        type="number"
                        v-model.number="
                          model.addresses[address.id].redeemables[
                            userQuotable.id
                          ]
                        "
                      />
                    </div>
                    <div
                      :class="{
                        'text-sm mx-2':
                          addressDistributionStatus[userQuotable.id],
                      }"
                    >
                      de {{ userQuotable.quantity }}
                      -
                      {{
                        userQuotable.quotable.redeemable_type ===
                        "App\\Models\\Product"
                          ? userQuotable.quotable.display_name
                          : userQuotable.quotable.redeemable.product
                              .storefront_redeemables[0].display_name +
                            " - " +
                            userQuotable.quotable.redeemable.value
                      }}
                    </div>
                    <div>
                      <img
                        :src="userQuotable.quotable.media[0].url"
                        alt=""
                        width="100"
                        class="shadow-lg rounded-lg"
                      />
                    </div>
                    <div
                      class="uk-text-success"
                      uk-icon="check"
                      v-if="addressDistributionStatus[userQuotable.id]"
                    ></div>
                  </div>
                </div>
              </label>
            </div>
            <div
               v-else-if="showAllAddresses"
                class="uk-card uk-card-default uk-padding-small uk-border-rounded"
            >
              <label>
                <div class="uk-flex uk-flex-between">
                  <div class="uk-text-bold uk-text-large">
                    <input
                        v-if="multipleShippingAddresses"
                        class="uk-checkbox"
                        type="checkbox"
                        v-model="model.addresses[address.id].selected"
                    />
                    <input
                        v-else
                        class="uk-radio"
                        type="radio"
                        name="address"
                        v-model="mainAddressId"
                        :value="address.id"
                    />
                    <span class="uk-margin-small-left"
                    >{{ address.first_name }} {{ address.last_name }}</span
                    >
                  </div>

                  <div
                      v-if="address.id in shippingLabels"
                      class="uk-margin-right uk-link-reset uk-flex uk-flex-middle"
                  ></div>
                </div>
                <div>
                  <span class="uk-text-bold">Teléfono:</span>
                  {{ address.phone }}
                </div>
                <div>
                  <span class="uk-text-bold">Dirección:</span>
                  {{ address.address }}, Zona
                  {{ address.zone }}
                </div>
                <div>
                  {{ address.city.name }}, {{ address.city.state.name }},
                  {{ address.city.state.country.spanish }}
                </div>

                <div
                    class="uk-margin-top uk-text-bold"
                    v-if="
                    multipleShippingAddresses &&
                    model.addresses[address.id].selected
                  "
                >
                  <div>Distribución de items</div>

                  <div
                      v-for="(userQuotable, index) in userQuotables"
                      :key="
                      'address-' + address.id + '-userQuotableIndex-' + index
                    "
                      class="flex items-center justify-between mt-2"
                  >
                    <div>
                      <input
                          class="w-12 bg-gray-100 border border-gray-300 rounded-lg p-1 text-center"
                          type="number"
                          v-model.number="
                          model.addresses[address.id].redeemables[
                            userQuotable.id
                          ]
                        "
                      />
                    </div>
                    <div
                        :class="{
                        'text-sm mx-2':
                          addressDistributionStatus[userQuotable.id],
                      }"
                    >
                      de {{ userQuotable.quantity }}
                      -
                      {{
                        userQuotable.quotable.redeemable_type ===
                        "App\\Models\\Product"
                            ? userQuotable.quotable.display_name
                            : userQuotable.quotable.redeemable.product
                                .storefront_redeemables[0].display_name +
                            " - " +
                            userQuotable.quotable.redeemable.value
                      }}
                    </div>
                    <div>
                      <img
                          :src="userQuotable.quotable.media[0].url"
                          alt=""
                          width="100"
                          class="shadow-lg rounded-lg"
                      />
                    </div>
                    <div
                        class="uk-text-success"
                        uk-icon="check"
                        v-if="addressDistributionStatus[userQuotable.id]"
                    ></div>
                  </div>
                </div>
              </label>
            </div>
            </transition>

          </div>

        </div>
        <div  v-if="!multipleShippingAddresses" class="m-auto w-full flex  justify-center my-6">
          <button
            class="w-64 uk-button-primary text-white font-bold py-2 px-4 rounded-2xl"
            @click="showAllAddressesAction()"
          >
            <div  class="flex items-center justify-center">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                </svg>

              </div>
              <div  v-if="!showAllAddresses" class="mt-1 ml-2">
                Ver mas direcciones
              </div>
              <div  v-else class="mt-1 ml-2">
                Ocultar direcciones
              </div>
            </div>
          </button>
        </div>
        <!-- End Shipping addresses -->
      </div>

      <div class="w-full mt-4 flex flex-col items-center justify-center">
        <ValidationObserver v-slot="{ invalid }" class="w-full">
          <div class="w-full bg-gray-100 border rounded-xl">
            <div
              class="py-2 flex items-center justify-center bg-gray-900 border rounded-xl"
            >
              <div class="text-lg font-bold text-center text-white">
                Balance
              </div>
            </div>
            <div class="flex items-center justify-center mt-2">
              <div class="font-bold mr-4">Disponibles:</div>
              <div class="flex justify-center items-center">
                <div>
                  {{ formatThousands(pointsBalance) }}
                  {{ pointsName }}
                </div>
              </div>
            </div>
            <div class="flex items-center justify-center bg-red-100 px-4 py-2">
              <div class="font-bold mr-4">- Canje:</div>
              <div class="flex justify-center items-center">
                <div>
                  {{
                    formatThousands(
                      userQuotables.reduce(
                        (accum, userQuotable) =>
                          accum +
                          userQuotable.quotable.current_price *
                            userQuotable.quantity,
                        0
                      )
                    )
                  }}
                  {{ pointsName }}
                </div>
              </div>
            </div>

            <div
              class="flex items-center justify-center bg-gray-100 px-4 py-2"
              :class="
                pointsBalance -
                  userQuotables.reduce(
                    (accum, userQuotable) =>
                      accum +
                      userQuotable.quotable.current_price *
                        userQuotable.quantity,
                    0
                  ) <
                0
                  ? 'text-red-500 bg-red-100'
                  : 'text-green-500'
              "
            >
              <div class="font-bold mr-4">Saldo:</div>
              <div class="flex justify-center items-center font-bold">
                <div>
                  <img
                    v-if="pointsIcon"
                    :src="pointsIcon"
                    alt=""
                    width="50px"
                  />
                </div>

                <div>
                  {{
                    formatThousands(
                      pointsBalance -
                        userQuotables.reduce(
                          (accum, userQuotable) =>
                            accum +
                            userQuotable.quotable.current_price *
                              userQuotable.quantity,
                          0
                        )
                    )
                  }}
                  {{ pointsName }}
                </div>
              </div>
            </div>
          </div>

          <div class="uk-text-center mt-4" v-if="hasPointsAvailable">
            <button
              test="complete-checkout-button"
              class="w-full uk-button-primary text-white font-bold py-2 px-4 rounded-2xl"
              :disabled="invalid || isLoading || !areAllSelectsFilled"
              @click="redeemProducts"
            >
              <div class="flex items-center justify-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                </div>
                <div class="ml-2">
                  <span v-if="isLoading" uk-spinner></span>

                  <span v-else> Completar compra </span>
                </div>
              </div>
            </button>
          </div>

          <div class="uk-text-center uk-margin-top">
            <a
              href="/cart"
              class="block w-full bg-gray-500 text-white font-bold py-2 px-4 rounded-2xl"
            >
              <div class="flex items-center justify-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                </div>
                <div class="ml-2">
                  <span>Regresar al carrito</span>
                </div>
              </div>
            </a>
          </div>
        </ValidationObserver>
      </div>
    </div>

    <div id="wait-modal" class="w-screen h-full" v-if="isLoading">
      <div class="flex w-full h-full items-center justify-center">
        <div
          class="flex flex-col items-center p-6 shadow-2xl border-4"
          style="background-color: #f2f2f2"
        >
          <div class="text-l">
            Estamos procesando su orden para brindarle la mejor experiencia
            posible. <br />
            <strong class="">
              Por favor, tenga paciencia mientras la aplicación completa su
              solicitud.
            </strong>
          </div>
          <div class="mt-4">
            <img
              src="https://media.tenor.com/WYKoRh1NGPEAAAAd/truck-delivery.gif"
              alt=""
              width="200"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

#wait-modal {
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: 9999999;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Checkout",

  components: {},

  data() {
    return {
      isConfirmTerms: false,
      showTerms: false,
      array_address: {
        type: Array,
        required: true,
      },
      size: {
        type: Number,
        required: false,
        default: 3,
      },
      pageTotal: 100,
      pageNumber: 0,
      show: false,
      addresses_storefront: [],
      addresses_storefront_default: null,
      multipleShippingAddresses: false,
      userQuotables: [],
      userQuotablesSnapshot: [],
      isLoading: false,
      addresses: [],
      addresSnapshot: [],
      mainAddressId: null,
      isLoadingShippingQuote: false,
      model: {
        addresses: {},
      },
      shippingLabels: [],
      latitude: null,
      longitude: null,
      showAllAddresses: false,
    };
  },

  computed: {
    ...mapGetters([
      "pointsName",
      "pointsIcon",
      "pointsBalance",
      "storefrontStorefront",
    ]),
    addressDistributionStatus() {
      return this.userQuotables.reduce((accum, userQuotable) => {
        accum[userQuotable.id] =
          userQuotable.quantity ===
          Object.values(this.model.addresses)
            .filter((address) => address.selected)
            .reduce((accum, address) => {
              return accum + address.redeemables[userQuotable.id];
            }, 0);

        return accum;
      }, {});
    },

    hasPointsAvailable() {
      return (
        this.pointsBalance -
          this.userQuotables.reduce(
            (accum, userQuotable) =>
              accum +
              userQuotable.quotable.current_price * userQuotable.quantity,
            0
          ) >=
        0
      );
    },

    areAllSelectsFilled() {
      if (!this.multipleShippingAddresses) {
        return true;
      }
      return this.userQuotables.every(
        (userQuotable) => userQuotable.selectedAddressId
      );
    },

    groupedProducts() {
      const groups = {};
      this.userQuotables.forEach((quotable) => {
        const key = quotable.id; // Asumiendo que existe una propiedad 'product_id'
        if (!groups[key]) {
          groups[key] = {
            product: quotable, // Información del producto
            items: [], // Items individuales
            isOpen: false, // Estado para controlar si el grupo está expandido o colapsado
          };
        }
        groups[key].items.push(quotable);
      });
      return Object.values(groups);
    },
  },

  mounted() {
    this.getQuoteInfo();
    navigator.geolocation.getCurrentPosition(function (position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;

      console.log(this.latitude, "XD");
      console.log(this.longitude, "XD");
    });
  },

  updated() {
    this.updateModelAddresses();
  },

  methods: {
    ...mapActions(["pointsFetchBalance"]),

    showAllAddressesAction() {
      this.showAllAddresses = !this.showAllAddresses;
    },

    acceptTerms() {
      this.isConfirmTerms = true;
      this.showTerms = false;

      return this.isConfirmTerms;
    },

    cancelTerms() {
      this.isConfirmTerms = false;
      this.showTerms = false;
      return this.isConfirmTerms;
    },

    splitUserQuotables() {
      let splitQuotables = [];
      this.userQuotables.forEach((quotable) => {
        for (let i = 0; i < quotable.quantity; i++) {
          let singleQuotable = { ...quotable, quantity: 1 };
          splitQuotables.push(singleQuotable);
        }
      });
      this.userQuotables = splitQuotables;
    },

    consolidateUserQuotables() {
      let consolidated = {};

      this.userQuotables.forEach((quotable) => {
        if (!consolidated[quotable.quotable_id]) {
          consolidated[quotable.quotable_id] = { ...quotable };
        } else {
          consolidated[quotable.quotable_id].quantity += quotable.quantity;
        }
      });

      this.userQuotables = Object.values(consolidated);
    },

    redeemProducts() {
      this.showTerms = true;
    },

    redeemRequest() {
      this.isLoading = true;
      this.axios
        .post("/quotes/redeem", {
          addresses: Object.entries(this.model.addresses).reduce(
            (accum, entry) => {
              if (entry[1].selected) {
                accum[entry[0]] = entry[1].redeemables;
              }

              return accum;
            },

            {}
          ),

          storefront_id: this.storefrontStorefront.id,
        })
        .then(({ data: { redeem_id } }) => {
          this.pointsFetchBalance();
          this.$router.push("/redeem/" + redeem_id);
        })
        .catch((response) => {
          console.log(response);
          this.getQuoteInfo(false);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    nextPage() {
      this.pageNumber++;
      console.log(this.pageNumber);
      console.log(this.pageTotal);
      this.paginatedData();
    },
    prevPage() {
      this.pageNumber--;
      this.paginatedData();
    },
    showHide() {
      this.show = !this.show;
    },
    pageCount() {
      let l = this.addresses_storefront.length,
        s = 3;
      this.pageTotal = Math.ceil(l / s);
      return Math.ceil(l / s);
    },
    paginatedData() {
      const start = this.pageNumber * 3,
        end = start + 3;
      return (this.array_address = this.addresses_storefront.slice(start, end));
    },
    getQuoteInfo(refetchAddresses = true) {
      this.isLoading = true;
      this.axios
        .get("/quotes?saved_for_later=0")
        .then(({ data: { userQuotables } }) => {
          this.userQuotables = userQuotables;
          if (refetchAddresses) {
            this.fetchAddresses();
            this.storeFrontAddresses();
            this.storeFrontDefaultAddresses();
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getShippingQuote() {
      this.isLoadingShippingQuote = true;
      let destinies = Object.keys(this.model.addresses)
        .filter((addressId) => this.model.addresses[addressId].selected)
        .map((addressId) => ({
          address_id: addressId,
        }));

      if (destinies.length === 0) {
        return false;
      }

      this.axios
        .post("store/shipping/quote", {
          destinies: destinies,
        })
        .then(({ data: { shippingLabels } }) => {
          this.shippingLabels = shippingLabels;
        })
        .finally(() => {
          this.isLoadingShippingQuote = false;
        });
    },
    fetchAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/addresses")
        .then(({ data }) => {
          this.addresses = data.addresses;
          this.model.addresses = this.addresses.reduce(
            (accum, address, index) => {
              accum[address.id] = {
                selected: index === 0,
                redeemables: this.userQuotables.reduce(
                  (accum, userQuotable) => {
                    accum[userQuotable.id] = 0;

                    return accum;
                  },
                  {}
                ),
              };

              return accum;
            },
            {}
          );

          if (this.addresses.length) {
            this.mainAddressId = this.addresses[0].id;
          }
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    storeFrontAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/storefronts/" + this.storefrontStorefront.id)
        .then(({ data }) => {
          this.addresses_storefront = data.addresses;
          this.totalPages = Math.round(
            parseFloat(this.addresses_storefront.length) / 3
          );
          this.pageCount();
          this.paginatedData();
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    storeFrontDefaultAddresses() {
      this.isLoadingAddresses = true;
      this.axios
        .get("/storefronts_default/" + this.storefrontStorefront.id)
        .then(({ data }) => {
          if (data) {
            this.addresses_storefront_default = data.addresses;
          }
        })
        .finally(() => {
          this.isLoadingAddresses = false;
        });
    },
    updateAddressQuantities() {
      this.addresses.forEach((address) => {
        if (address.id === this.mainAddressId) {
          this.model.addresses[address.id].selected = true;
          this.userQuotables.forEach((userQuotable) => {
            this.model.addresses[address.id].redeemables[userQuotable.id] =
              userQuotable.quantity;
          });
        } else {
          this.model.addresses[address.id].selected = false;
          this.userQuotables.forEach((userQuotable) => {
            this.model.addresses[address.id].redeemables[userQuotable.id] = 0;
          });
        }
      });
    },

    updateModelAddresses() {
      if (!this.multipleShippingAddresses) {
        return true;
      }
      // Reiniciar el objeto addresses en el modelo
      this.model.addresses = this.addresses.reduce((accum, address) => {
        accum[address.id] = { selected: false, redeemables: {} };
        return accum;
      }, {});

      this.userQuotables.forEach((userQuotable) => {
        let addressId = userQuotable.selectedAddressId;
        if (addressId) {
          if (!this.model.addresses[addressId].redeemables[userQuotable.id]) {
            // Inicializar si este quotable aún no se ha añadido a la dirección
            this.model.addresses[addressId].redeemables[userQuotable.id] = 0;
          }
          // Sumar la cantidad de cada quotable
          this.model.addresses[addressId].redeemables[userQuotable.id] +=
            userQuotable.quantity;
          this.model.addresses[addressId].selected = true;
          let isDistributionCorrect = true;

          this.addressDistributionStatus[userQuotable.id] =
            isDistributionCorrect;
        }
      });

      this.getShippingQuote();
    },

    confirmAddres() {
      this.updateModelAddresses();
    },

    allSelectsFilled() {
      if (!this.multipleShippingAddresses) {
        return true;
      }
      return this.userQuotables.every(
        (userQuotable) => userQuotable.selectedAddressId
      );
    },
  },

  watch: {
    isConfirmTerms() {
      if (this.isConfirmTerms) {
        this.redeemRequest();
      }
    },

    multipleShippingAddresses() {
      if (this.multipleShippingAddresses) {
        this.splitUserQuotables();
      } else {
        this.consolidateUserQuotables();
      }
    },
    userQuotables() {
      //this.updateModelAddresses();
    },
    mainAddressId() {
      this.addresses.sort((a, b) => {
        if (a.id === this.mainAddressId) {
          return -1;
        } else if (b.id === this.mainAddressId) {
          return 1;
        } else {
          return a.id - b.id;
        }
      });
      this.updateAddressQuantities();
    },
    model: {
      deep: true,
      handler() {
        if (Object.keys(this.model.addresses).length) {
          //this.getShippingQuote();
        }
      },
    },
  },
};
</script>
